import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import { useEffect, useState } from 'react'

import AnimatedLetters from '../AnimatedLetters'
import {
  faLinux,
  faDocker,
  faAws,
  faNodeJs,
  faReact,
  faPython,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './index.scss'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const jobArray = []

  const nameArray = [' ', 'J', 'e', 's', 's', 'e']
  const jobArray1 = ['A Back End Software Engineer based in Austin Tx.']
  for (var i = 0; i < jobArray1.length; i++) {
    jobArray[i] = jobArray1
  }
  useEffect(() => {
    setTimeout(() => {
      return setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  return (
    <>
      <div className='container home-page'>
        <div className='text-zone'>
          <h1>
            {' '}
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={17}
            />
          </h1>
          <h2> Back End Developer / Python Expert / Youtuber</h2>
          <Link to='/contact' className='flat-button'>
            CONTACT ME
          </Link>
        </div>
        <div className='stage-cube-cont'>
          <div className='cubespinner'>
            <div className='face1'>
              <FontAwesomeIcon icon={faLinux} color='#DD0031' />
            </div>
            <div className='face2'>
              <FontAwesomeIcon icon={faPython} color='#4B8BBE' />
            </div>
            <div className='face3'>
              <FontAwesomeIcon icon={faDocker} color='#28A4D9' />
            </div>
            <div className='face4'>
              <FontAwesomeIcon icon={faReact} color='#5ED4F4' />
            </div>
            <div className='face5'>
              <FontAwesomeIcon icon={faAws} color='#EFD81D' />
            </div>
            <div className='face6'>
              <FontAwesomeIcon icon={faNodeJs} color='#EC4D28' />
            </div>
          </div>
        </div>
        
      </div>

      <Loader type='ball-scale-ripple-multiple' />
      
    </>
    
  )
}

export default Home
