import Loader from 'react-loaders'
import './index.scss'

const Portfolio = () => {
 
  return (
    <>
      <div className='container portfolio-page'>
        
        <div className='full'>
          <div className='left'>
            <div className='image'>
              <img
                src='https://media.geeksforgeeks.org/wp-content/uploads/20220202083519/gfglogo.png'
                alt='gfg-logo'
              />
            </div>
            <div className='Contact'>
              <h2>Contact</h2>
              <p>jesse1517@gmail@gmail.com</p>
              <p>(503)-853-0076</p>
            </div>
            <div className='Skills'>
              <h2>Skills</h2>
                <ul>
                <li>
                  <b>Python</b>
                </li>
                <li>
                  <b>Networking</b>
                </li>
                <li>
                  <b>Docker</b>
                </li>
                <li>
                  <b>Terraform</b>
                </li>
                <li>
                  <b>Java</b>
                </li>
                <li>
                  <b>MySQL</b>
                </li>
                <li>
                  <b>Javascript</b>
                </li>
                <li>
                  <b>Linux</b>
                </li>
                <li>
                  <b>Kubernetes</b>
                </li>
                <li>
                  <b>EC2</b>
                </li>
                <li>
                  <b>VPC</b>
                </li>
                <li>
                  <b>AWS</b>
                </li>
                <li>
                  <b>DNS</b>
                </li>
                <li>
                  <b>ECS</b>
                </li>
                <li>
                  <b>EKS</b>
                </li>
                <li>
                  <b>Node Js</b>
                </li>
                <li>
                  <b>React</b>
                </li>
                <li>
                  <b>HTML</b>
                </li>
                <li>
                  <b>CSS</b>
                </li>
              </ul>
            </div>
            <div className='Hobbies'>
              <h2>Hobbies</h2>
              <ul>
                <li>Hockey</li>
                <li>Chess</li>
                <li>Reading</li>
              </ul>
            </div>
          </div>
          <div className='right'>
            <div className='name'>
              <h1>Jesse Leonard</h1>
            </div>
            <div className='title'></div>
            <div className='Summary'>
              <h2>Summary</h2>
              <p>
                I’m a Backend Software Engineer that has really enjoyed working
                for fast paced start ups in my career. I’m very motivated by
                learning and implementing new technologies that save my
                companies time and money. I strive to be the best engineer I can
                be and am working towards being certified as an AWS Solutions
                Architect.
              </p>
            </div>
            <div className='Experience'>
              <h2>Experience</h2>
              <br></br>
              <h2>Vectra AI - Software Engineer</h2>
              <p>February 2021 - Present</p>

              <ul>
                <li>
                  Designed built and deployed fault tolerant distributed systems
                  forwarding network data between on premise and cloud
                  infrastructure.
                </li>
                <li>
                  Congifured Kubernetes in AWS using design patterns that
                  allowed for automatic deployment and configuration.
                </li>
                <li>
                  Led the deployment of the monthly release of our product. This
                  included running the deployment in jenkins, making
                  announcements daily about any build and deployment issues,
                  promoting from dev to stage to prod, and testing the
                  deployments.
                </li>
                <li>
                  Worked defining the auto scaling groups and launch templates
                  of our architecture.
                </li>
                <li>
                  Led projects where I was tasked with designing, planning, and
                  creating tickets for new pieces of architecture.
                </li>
                <li>
                  Worked on implementing a cli that was responsible for
                  provisioning and updating resources needed when onboarding
                  customers.
                </li>
                <li>
                  Built a user management API integrating Cognito idp to allow
                  customers to securely log in, create, and update users.
                </li>
                <li>
                  Created Make files to dockerize our Django applications and
                  store them in ECS.
                </li>

                <li>
                  Wrote reusable terraform code that allowed us to have our own
                  dev tenants that was also usable in multiple deployment stages
                </li>

                <li>
                  Created custom jwt authentication back end in Django REST
                  Framework. Integrating our Cognito User Pool to obtain jwt's
                  and authenticate user requests.
                </li>

                <li>
                  Configured our application with layered API's to obtain
                  credentials to inject into our containers.
                </li>
              </ul>
              <br></br>
              <h2>Omnivector Solutions - Software Engineer</h2>
              <p>February 2019 - March 2021</p>
              <ul>
                <li>
                  Contributed to the automation software that allowed us to
                  dynamically configure, deploy, and horizontally scale HPC
                  systems for our enterprise clients.
                </li>
                <li>
                  Extensive work in Linux to be able configure to configure our
                  software across multiple distrubtions, ie Centos and Ubuntu
                  server
                </li>
                <li>
                  Utilized automation tools such as Juju and Kubernetes to
                  deploy and maintain clusters of 200+ servers.
                </li>
                <li>
                  Created CI/CD pipelines to effectively set up the environment,
                  build the software, test, version, and release our products.
                </li>
                <li>
                  Help Set up our MaaS environment. This included setting up the
                  network, making nodes discoverable, setting up the network
                  switches and firewall rules.
                </li>
                <li>
                  Set up comprehensive logging and monitoring tools such as
                  logstash, kibana, and Prometheus to ensure software is 100%
                  operational.
                </li>
              </ul>
            </div>
            <div className='Education'>
              <h2>Education</h2>
              <p>University of Oregon 2018</p>

              <p>Bachlerors of Science</p>
              <p>Computer and Information Science</p>
            </div>
            <div className='project'>
              <h2>Side Projects</h2>

              <ul>
                <li>
                  <h2>Youtube</h2>
                  <p>
                    I have created a youtube channel that explains various
                    computer programming topics. I started off teaching an
                    introduction to python series. My best video so far is a
                    video that teaches people all of the principles of RESTful
                    API architecture. I wish to go on to make more videos about
                    kubernetes and networking.
                  </p>
                </li>
                <li>
                  <h2>KeyShot Farms Project</h2>
                  <p>
                    I came in contact with a small business that ran a render
                    farm. I was contracted out to design and build automation
                    software to fully utilize his resources. Through this
                    process I built a distributed system that would dynamically
                    configure his servers with a sufficient amount of resources
                    based on the needs of his customers and time of day. At
                    times, most of his resources were idle and not used. We made
                    it so these resources could be dynamically configured to be
                    used by other customers.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Loader type='ball-scale-ripple-multiple' />
    </>
  )
}

export default Portfolio
